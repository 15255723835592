import React, { Component } from 'react';
import Select from "react-select";
import queryString from 'query-string'

class DiagnositicNexus extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedValue: this.props.selectedValue,
            applicableSymptoms: this.props.symptoms.slice(),
            applicableDiagnosis: [], // To show all by default: this.props.diagnosis.slice()
            customStyles: {
                /*option: (provided, state) => ({
                    ...provided,
                    borderBottom: '1px dotted pink',
                    color: state.isSelected ? 'red' : 'blue',
                    padding: 20,
                }),*/
                control: () => ({
                    // none of react-select's styles are passed to <Control />
                    width: 200,
                })/*,
                singleValue: (provided, state) => {
                    const opacity = state.isDisabled ? 0.5 : 1;
                    const transition = 'opacity 300ms';

                    return { ...provided, opacity, transition };
                }*/
            },


        }
        this.onSelect = this.onSelect.bind(this);
    }

    render() {
        return (
            <div>
                <div>
                    <Select
                        defaultValue={this.state.selectedValue}
                        onChange={this.onSelect}
                        options={this.state.applicableSymptoms}
                        isMulti={true}
                        placeholder="Select one or more symptoms and signs..."
                    />
                    <table className='table table-striped' aria-labelledby="tabelLabel">
                        <thead><tr><th>Hypothetical Diagnoses Based on Selected Symptoms and Signs</th></tr></thead>
                        <tbody>
                            {this.state.applicableDiagnosis.map(diagnosis =>
                                <tr key={diagnosis.id}>
                                    <td>
                                        {diagnosis.url != "" ? (
                                            <a target="_blank" href={diagnosis.url}>{diagnosis.description}</a>
                                        ) : (
                                                <div>{ diagnosis.description }</div>
                                            )}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    onSelect(selectedList, selectedItem) {
        //We want to gather all the symptoms that could possibly create a diagnosis, and rule out the ones that can't
        var possibleSymptoms = [];
        //These are our currently selected symptom's ID values
        var selectedSymptomIDS = [];
        //There's nothing selected
        
        if (selectedList == null) {
            selectedList = [];
            /*
            window.alert("Nothing selected");
            this.setState({ applicableDiagnosis: this.props.diagnosis.slice(), applicableSymptoms: this.props.symptoms.slice() });
            return;
            */
        }
        //window.alert("Item(s) selected");
        //Push all symptom id's into our list
        selectedList.forEach(symptom => {
            selectedSymptomIDS.push(symptom.value);
        });
        /*
        if (selectedSymptomIDS === undefined || selectedSymptomIDS.length == 0) {
            this.setState({ applicableDiagnosis: this.props.diagnosis, applicableSymptoms: this.props.symptoms });
            return;
        }
        */

        //This is our list of what to display on the table
        var newDiagnosisList = [];
        //const responseD = await fetch('diagnosis');
        //This is our list of all possible diagnosis
        var diagnosisList = this.props.diagnosis.slice();//await responseD.json();
        //A checker function to see if an array contains every entry in another array
        let checker = (arr, target) => target.every(v => arr.includes(v));

        //If a diagnosis has every selected symptom, then it will be displayed on the table as a remaining possibility
        diagnosisList.forEach(diagnosis => {
            if (checker(diagnosis.symptomIDs, selectedSymptomIDS)) //If there is an overlap in the arrays, we add it
            {
                //If nothing is selected, we display no diagnosis
                if (selectedSymptomIDS.length > 0) {
                    newDiagnosisList.push(diagnosis);
                }
                //Also going to check what symptoms are possible under this diagnosis and add it to our list
                diagnosis.symptomIDs.forEach(symptomID => {
                    if (!possibleSymptoms.includes(symptomID))
                        possibleSymptoms.push(symptomID);
                })
            }
        })

        var newSymptomList = this.props.symptoms.slice();
        //Disable everything that isn't in our possible symptoms list
        newSymptomList.forEach(symptom => {
            if (possibleSymptoms.includes(symptom.value))
                symptom.isDisabled = false;
            else
                symptom.isDisabled = true;
        })
        //Sort the disabled ones to the bottom
        newSymptomList.sort(function (x, y) { return x.isDisabled - y.isDisabled });

        this.setState({ applicableDiagnosis: newDiagnosisList, applicableSymptoms: newSymptomList, selectedValue: selectedList });

    }


}

/*
onSelect(selectedList, selectedItem) {
;
}

onRemove(selectedList, removedItem) {
;
}
*/

export class FetchData extends Component {
    static displayName = FetchData.name;

    constructor(props) {
        super(props);
        this.state = { symptoms: [], diagnosis: [], loadingS: true, loadingD: true, id: 0 };
    }
    /*
    static getDerivedStateFromProps(nextProps, prevState) {
        this.populateSymptomDiagnosisData();
    }
    */

    static renderDiagnosticsTool(symptoms, diagnosis, selectedValue) {
        return (<DiagnositicNexus symptoms={symptoms} diagnosis={diagnosis} selectedValue={selectedValue} />);
    }

    render() {
        if (this.state.id != this.props.match.params.aotc) {
            this.setState({ id: this.props.match.params.aotc, symptoms: [], diagnosis: [], loadingS: true, loadingD: true });
            this.populateSymptomDiagnosisData();
        }

        let contents = this.state.loadingS || this.state.loadingD
            ? <p><em>Loading...</em></p>
            : FetchData.renderDiagnosticsTool(this.state.symptoms, this.state.diagnosis, this.state.selectedValue);

        return (
            <div>
                <h1 id="tabelLabel" >Diagnostic Nexus: {this.state.selectedValue?.toString()}</h1>
                {contents}
            </div>
        );
    }

    async populateSymptomDiagnosisData() {
        //const values = queryString.parse(this.props.location.search)
        const response = await fetch('symptom/api/values/' + this.props.match.params.aotc);
        const data = await response.json();
        //window.alert(JSON.stringify(data));
        this.setState({ symptoms: data, loadingS: false });

        const responseD = await fetch('diagnosis/api/values/' + this.props.match.params.aotc);
        const dataD = await responseD.json();
        //window.alert(JSON.stringify(dataD));
        this.setState({ diagnosis: dataD, loadingD: false });

    }
}
