import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (
      <div>
        <h1>Welcome to the Weston Diagnostic Nexus!</h1>
            <p>Please select one of the Diagnostic Nexus from the selection above.</p>
      </div>
    );
  }
}
